import React from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import Button from "./Button";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import {DISCORD_CLIENT_ID, uuidv4} from "../shared";

class LinkDiscordButton extends React.Component {
    
    linkDiscord = () => {
        const state = uuidv4();
        Cookies.set("discord_oauth2_state", state);
        const redirectURL = encodeURIComponent(window.location.origin + "/oauth2callback/discord");
        const url = `https://discord.com/api/oauth2/authorize?response_type=code&client_id=${DISCORD_CLIENT_ID}&scope=identify&state=${state}&redirect_uri=${redirectURL}&prompt=consent`;
        window.location.href = url;
    }

    render(){
        const linked = this.props.data != null && this.props.data != undefined && "username" in this.props.data;
        return (
            <Button content={linked ? `Discord linked to ${this.props.data.username}` : `Link Discord`} icon={faDiscord} {...this.props} onClick={this.linkDiscord} />
        );
    }
}

LinkDiscordButton.propTypes = {
};

export default LinkDiscordButton;