import React from "react";
import AppleBadge from "../resources/apple-badge.svg";
import MicrosoftBadge from "../resources/microsoft-badge.png";
import SteamBadge from "../resources/steam.jpg";
import firebase from "firebase/app";
import { APPLE_DOWNLOAD_URL, MICROSOFT_DOWNLOAD_URL, STEAM_DOWNLOAD_URL } from "../shared";

export default class DownloadButtons extends React.Component {
    constructor(props) {
        super(props);
        this.analytics = firebase.analytics();
    }
    logAnalyticsEvent(platform) {
        this.analytics.logEvent("download_btn_press", {platform});
    }
    render(){
        return (
            <div className="download-buttons">
                <a href={APPLE_DOWNLOAD_URL} target="_blank" rel="noreferrer" onClick={() => {
                    this.logAnalyticsEvent("apple");
                }}><img src={AppleBadge} alt="Download on the Mac App Store" style={{height: "75px"}} /></a>
                <a href={MICROSOFT_DOWNLOAD_URL} target="_blank" rel="noreferrer" onClick={() => {
                    this.logAnalyticsEvent("windows");
                }}><img src={MicrosoftBadge} alt="Get it from Microsoft" style={{height: "75px", marginLeft: "10px"}}/></a>
                <a href={STEAM_DOWNLOAD_URL} target="_blank" rel="noreferrer" onClick={() => {
                    this.logAnalyticsEvent("steam");
                }}><img src={SteamBadge} alt="Get it on Steam" style={{height: "75px", marginLeft: "10px", padding: "10px", boxSizing: "border-box", backgroundColor: "#FFFFFF", borderRadius: "10px"}}/></a>
            </div>
        );
    }
}
