import React from "react";
import firebase from "firebase/app";
import moment from "moment";
import "./styles/ProfilePage.scss";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import {getFlairColor} from "../shared";
import Title from "../components/Title";
import Loader from "../components/Loader";
import Message from "../components/Message";
import RobotImg from "../resources/robot.png";
import BunnyImg from "../resources/bunny.png";
import VacuumImg from "../resources/vacuum.png";
import ErrorMessage from "../components/ErrorMessage";
import { Helmet } from "react-helmet";

export default class ProfilePage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: null,
            error: null
        };
        this.db = firebase.firestore();
        this.fetchData();
    }

    fetchData = async () => {
        let data;
        try {
            data = await this.db.collection("users").doc(this.props.firebaseUser.uid).get();
        } catch(e) {
            console.error(e);
            this.setState({error: "fetch-user/unknown", loading: false});
        }
        this.setState({data: data.data(), loading: false});
    }
    
    render(){
        const helmet = (
            <Helmet>
                <title>Profile - Hide And Seek Scramble!</title>
            </Helmet>
        );
        if(this.state.error != null){
            return (
                <div className="purple stripesbg main-content">
                    {helmet}
                    <ErrorMessage code={this.state.error} />
                </div>
            );
        }
        if(this.state.loading || this.state.data == null){
            return (
                <div className="purple stripesbg">
                    {helmet}
                    <Loader />
                </div>
            )
        }
        const {data} = this.state;
        const winsTotal = Object.values(data.stats.wins).reduce((total, i) => total + i, 0);
        const lossesTotal = Object.values(data.stats.losses).reduce((total, i) => total + i, 0);
        const drawsTotal = Object.values(data.stats.draws).reduce((total, i) => total + i, 0);
        const robotGamesPlayed = data.stats.wins.robot + data.stats.losses.robot + data.stats.draws.robot;
        const bunnyGamesPlayed = data.stats.wins.bunny + data.stats.losses.bunny + data.stats.draws.bunny;
        const vacuumGamesPlayed = data.stats.wins.vacuum + data.stats.losses.vacuum + data.stats.draws.vacuum;
        const playingSince = moment(data.createdOn.toDate()).format("MMMM YYYY");
        return (
            <div className="purple stripesbg main-content" id="profile-page-main">
                {helmet}
                <div style={{textAlign: "center"}}>
                    <Title content="Your Profile" icon={faUserCircle} />
                    <br />
                    <div className="name-badge" style={{
                        color: getFlairColor(data.unlockables.flairChoice)
                    }}>
                        {data.username}
                    </div>
                    <p id="playing-since">Playing since {playingSince}</p>
                    <table id="stats-table">
                        <tr>
                            <th id="table-header">Game Stats</th>
                            <th><img src={RobotImg} alt="Robot" /></th>
                            <th><img src={BunnyImg} alt="Bunny" /></th>
                            <th><img src={VacuumImg} alt="Vacuum" /></th>
                            <th>Total</th>
                        </tr>
                        <tr>
                            <th>Wins</th>
                            <td>{data.stats.wins.robot}</td>
                            <td>{data.stats.wins.bunny}</td>
                            <td>{data.stats.wins.vacuum}</td>
                            <td className="col-total">{winsTotal}</td>
                        </tr>
                        <tr>
                            <th>Losses</th>
                            <td>{data.stats.losses.robot}</td>
                            <td>{data.stats.losses.bunny}</td>
                            <td>{data.stats.losses.vacuum}</td>
                            <td className="col-total">{lossesTotal}</td>
                        </tr>
                        <tr>
                            <th>Draws</th>
                            <td>{data.stats.draws.robot}</td>
                            <td>{data.stats.draws.bunny}</td>
                            <td>{data.stats.draws.vacuum}</td>
                            <td className="col-total">{drawsTotal}</td>
                        </tr>
                        <tr>
                            <th>Games played</th>
                            <td>{robotGamesPlayed}</td>
                            <td>{bunnyGamesPlayed}</td>
                            <td>{vacuumGamesPlayed}</td>
                            <td id="games-played" className="col-total">{data.stats.gamesPlayed}</td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }
}