import React from "react";
import { Helmet } from "react-helmet";

export default class NotFoundPage extends React.Component {
    render(){
        return (
            <div className="purple stripesbg main-content">
                <Helmet>
					<title>404 - Hide And Seek Scramble!</title>
				</Helmet>
                <h1>404: Page not found</h1>
            </div>
        );
    }
}