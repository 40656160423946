import React from "react";
import PropTypes from "prop-types";

import Hex from "../resources/hex.png";

class Loader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            rotation: 0
        };
        const step = 20;
        const degPerSec = 200;
        setInterval(() => {
            this.setState({
                rotation: this.state.rotation + degPerSec * (step / 1000)
            });
        }, step);
    }
    
    render(){
        return (
            <div className="shade">
                <img src={Hex} alt="Loading icon" className="loader" style={{
                    transform: `rotate(${this.state.rotation}deg)`
                }} />
            </div>
        );
    }
}

Loader.propTypes = {
    
};

export default Loader;