import React from "react";
import "./styles/UserReviewPage.scss";
import firebase from "firebase/app";
import moment from "moment";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Title from "../components/Title";
import Button from "../components/Button";
import { faCheck, faHammer, faLock, faUnlock, faWrench } from "@fortawesome/free-solid-svg-icons";
import { getFlairColor, USERNAME_VALIDATION_REGEX } from "../shared";
import ERRORS from "../errors";
import ErrorMessage from "../components/ErrorMessage";
import Input from "../components/Input";
import { Helmet } from "react-helmet";

export default class UserReviewPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: null,
            firebaseId: null,
            error: null,
            newUsernameField: "",
            newModNotesField: "",
            banReasonField: "",
            banDurationField: 1
        };
        this.db = firebase.firestore();
        this.fetchData();
    }

    fetchData = async () => {
        const handleError = (e) => {
            console.error(e);
            if(e.code === "permission-denied"){
                this.setState({error: "moderation/permission-denied", loading: false});
                return;
            }
            this.setState({error: "moderation/firebase-error", loading: false});
        };
        this.setState({loading: true, data: null, firebaseId: null, newUsernameField: "", newModNotesField: "", banReasonField: "", banDurationField: 1});
        let doc;
        if(this.props.match.params.id){
            try {
                doc = await this.db.collection("users").doc(this.props.match.params.id).get();
                if(!doc.exists) {
                    this.setState({error: "moderation/user-nonexistent", loading: false});
                return;
                }
            } catch(e) { handleError(e); return; }
        } else {
            let docs;
            try {
                docs = await this.db.collection("users").where("moderation.needsApproval", "==", true).limit(1).get();
            } catch(e) { handleError(e); return; }
            if(docs.size === 0) {
                this.setState({error: "moderation/review-queue-empty", loading: false});
                return;
            }
            doc = docs.docs[0];
        }
        const data = doc.data();
        this.setState({
            loading: false,
            data,
            firebaseId: doc.id,
            newModNotesField: data.moderation.notes 
        });
    }

    resolve = async () => {
        if(this.state.firebaseId == null) return;
        this.setState({loading: true});
        try {
            await this.db.collection("users").doc(this.state.firebaseId).update({
                "moderation.needsApproval": false
            });
        } catch(e){
            console.error(e);
            this.setState({error: "moderation/firebase-error", loading: false});
            return;
        }
        this.fetchData();
    }

    toggleUsernameLock = async () => {
        if(this.state.firebaseId == null) return;
        this.setState({loading: true});
        try {
            await this.db.collection("users").doc(this.state.firebaseId).update({
                "usernameLocked": !this.state.data.usernameLocked
            });
        } catch(e){
            console.error(e);
            this.setState({error: "moderation/firebase-error", loading: false});
            return;
        }
        this.fetchData();
    }

    saveUsername = async () => {
        if(this.state.newUsernameField.length < 3 || this.state.newUsernameField.length > 16 || !USERNAME_VALIDATION_REGEX.test(this.state.newUsernameField)) return;
        this.setState({loading: true});
        try {
            await this.db.collection("users").doc(this.state.firebaseId).update({
                username: this.state.newUsernameField,
                previousUsernames: firebase.firestore.FieldValue.arrayUnion({
                    changedOn: new Date(),
                    username: this.state.newUsernameField,
                    moderator: this.props.firebaseUser.uid
                })
            });
        } catch(e){
            console.error(e);
            this.setState({error: "moderation/firebase-error", loading: false});
            return;
        }
        this.fetchData();
    }

    saveModNotes = async () => {
        if(this.state.firebaseId == null) return;
        this.setState({loading: true});
        try {
            await this.db.collection("users").doc(this.state.firebaseId).update({
                "moderation.notes": this.state.newModNotesField
            });
        } catch(e){
            console.error(e);
            this.setState({error: "moderation/firebase-error", loading: false});
            return;
        }
        this.fetchData();
    }

    banUser = async () => {
        const duration = parseInt(this.state.banDurationField);
        if(isNaN(duration)) return;
        if(duration < 1) return;
        if(this.state.firebaseId == null) return;
        this.setState({loading: true});
        const date = new Date();
        date.setDate(date.getDate() + duration);
        try {
            await this.db.collection("users").doc(this.state.firebaseId).update({
                "moderation.banExplanation": this.state.banReasonField,
                "moderation.bannedUntil": date,
                "moderation.banHistory": firebase.firestore.FieldValue.arrayUnion({
                    moderator: this.props.firebaseUser.uid,
                    explanation: this.state.banReasonField,
                    timestamp: new Date(),
                    until: date
                })
            });
        } catch(e){
            console.error(e);
            this.setState({error: "moderation/firebase-error", loading: false});
            return;
        }
        this.fetchData();
    }

    render(){
        const helmet = (
            <Helmet>
                <title>Moderation - Hide And Seek Scramble!</title>
            </Helmet>
        );
        if(this.state.error != null){
            return (
                <div className="purple stripesbg main-content">
                    {helmet}
                    <ErrorMessage code={this.state.error} />
                </div>
            );
        }
        if(this.state.loading || this.state.data == null){
            return (
                <div className="purple stripesbg">
                    {helmet}
                    <Loader />
                </div>
            )
        }
        const {data, newUsernameField} = this.state;
        const playingSince = moment(data.createdOn.toDate()).format("MMMM YYYY");
        const newUsernameIsValid = newUsernameField.length >= 3 && newUsernameField.length <= 16 && USERNAME_VALIDATION_REGEX.test(newUsernameField);
        return (
            <div className="purple stripesbg main-content" id="user-review-page-main">
                {helmet}
                <div style={{textAlign: "center"}}>
                    <Title id="main-title" content={this.props.match.params.id ? "User Profile" : "User Review Queue"} icon={faWrench} /><br />
                    {
                        data.moderation.needsApproval && (
                            <Button content="Resolve" icon={faCheck} onClick={this.resolve} />
                        )
                    }
                    <br />
                    <div className="name-badge" style={{
                        color: getFlairColor(data.unlockables.flairChoice)
                    }}>
                        {data.username}
                    </div>
                    <p id="playing-since">Account created {playingSince}</p>
                    <p id="account-id">Account ID <code>{this.state.firebaseId}</code></p>
                    <h2>Moderator Notes</h2>
                    <div id="mod-notes">
                        <Input type="textarea" placeholder="Moderator notes" value={this.state.newModNotesField} onChange={(e) => {
                            this.setState({newModNotesField: e.target.value});
                        }} /><br />
                        <Button content="Save" icon={faCheck} onClick={this.saveModNotes} disabled={this.state.newModNotesField === data.moderation.notes} />
                    </div>
                    <div id="split-pane">
                        <div id="mod-history">
                            <h2>Moderation History</h2>
                            {data.moderation.bannedUntil.toDate() - Date.now() > 0 ? (
                                <p className="negative">
                                    <p>Player is banned until {moment(data.moderation.bannedUntil.toDate()).format("MMM Do, YYYY [at] h:mm a")}</p>
                                    <p><b>Ban reason:</b> {data.moderation.banExplanation}</p>
                                </p>
                            ) : (
                                <p>Player is not banned.</p>
                            )}
                            <div id="ban-user">
                                <Input size="small" placeholder="Ban explanation" value={this.state.banReasonField} onChange={(e) => this.setState({banReasonField: e.target.value})} />
                                <Input size="small" placeholder="Ban duration (days)" type="number" value={this.state.banDurationField} onChange={(e) => {
                                    this.setState({banDurationField: e.target.value < 1 ? 1 : e.target.value});
                                }} />
                                <Button size="small" icon={faHammer} content="Ban player" onClick={this.banUser} disabled={isNaN(parseInt(this.state.banDurationField))} />
                            </div>
                            <p><b>Disconnect count:</b> {data.moderation.disconnectCount}</p>
                            <p><b>Last disconnect:</b> {moment(data.moderation.lastDisconnect.toDate()).format("MMM Do, YYYY [at] h:mm a")}</p>
                            {
                                data.moderation.banHistory.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate()).map((ban, idx) => {
                                    return (
                                        <div className="ban-entry" key={idx}>
                                            <p><b>Banned on:</b> {moment(ban.timestamp.toDate()).format("MMM Do, YYYY [at] h:mm a")}</p>
                                            <p><b>Banned until:</b> {moment(ban.until.toDate()).format("MMM Do, YYYY [at] h:mm a")}</p>
                                            <p><b>Reason:</b> {ban.explanation}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div id="divider" />
                        <div id="username-history">
                            <h2>Username History</h2>
                            <div id="username-change">
                                <Input size="small" placeholder="Change username" value={this.state.newUsernameField} onChange={(e) => this.setState({newUsernameField: e.target.value})} />
                                <Button size="small" icon={faCheck} onClick={this.saveUsername} disabled={!newUsernameIsValid} />
                            </div>
                            <p className={`${data.usernameLocked ? "negative" : ""}`}>{data.usernameLocked ? "Player is prevented from changing their username." : "Username is unlocked"}</p>
                            <Button size="small" content={data.usernameLocked ? "Unlock username" : "Lock username"} icon={data.usernameLocked ? faUnlock : faLock} onClick={this.toggleUsernameLock} />
                            {
                                data.previousUsernames.sort((a, b) => b.changedOn.toDate() - a.changedOn.toDate()).map((username, idx) => {
                                    return (
                                        <div className="username-entry" key={idx}>
                                            <p><b>{username.username}</b></p>
                                            <p><b>Changed on:</b> {moment(username.changedOn.toDate()).format("MMM Do, YYYY [at] h:mm a")}</p>
                                            {
                                                username.moderator != null && (
                                                    <p className="negative">By moderator</p>
                                                )
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
