import React from "react";
import PropTypes from "prop-types";

class Modal extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        return (
            <div className={`modal ${this.props.shown ? "shown" : ""}`}>
                <div className="modal-content stripesbg">
                    {this.props.content}
                    <div className="modal-footer">
                        {this.props.footer}
                    </div>
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    shown: PropTypes.bool.isRequired,
    content: PropTypes.element,
    footer: PropTypes.element
};

export default Modal;