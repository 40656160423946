import React from "react";
import PropTypes from "prop-types";

class Input extends React.Component {
    static defaultProps = {
        placeholder: "",
        value: "",
        type: "text"
    }

    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        return (
                this.props.type == "textarea" ? (
                    <textarea className={`custom ${this.props.size}`} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} style={this.props.style} />
                ) : (
                    <input className={`custom ${this.props.size}`} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange} type={this.props.type} style={this.props.style} />
                )
        );
    }
}

Input.propTypes = {
    size: PropTypes.oneOf(["regular", "small", "jumbo"]),
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(["text", "textarea"])
};

Input.defaultProps = {
    size: "regular"
}

export default Input;