import { faBook } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Title from "../components/Title";
import "./styles/LegalPage.scss";
import { Helmet } from "react-helmet";

export default class LegalPage extends React.Component {
    render(){
        return (
            <div className="purple stripesbg main-content" id="legal-page-main">
                <Helmet>
					<title>Legal - Hide And Seek Scramble!</title>
				</Helmet>
                <div style={{textAlign: "center"}}>
                    <Title icon={faBook} content="Privacy Policy" />
                </div>
                <p>By downloading "Hide And Seek Scramble!" ("the game") or signing up for an in-game account, you agree to this privacy policy. These terms may be changed at any time with or without notification to you.</p>
                <p>Kartfinity uses Google LLC.'s Firebase service to host its data. Any data we receive will be stored and processed on their servers.</p>
                <p>When you sign up for the game, we collect your email address, which is associated to your account. We also store data related to analytics and and account preferences. We reserve the right to add, delete, alter, or retrieve this data at any time.</p>
                <p>We use Google Analytics to collect data, such as sales, webpage views, and other statistics. You can view Google Analytics' privacy policy by <a href="https://marketingplatform.google.com/about/analytics/terms/us/">clicking here</a>.</p>
                <p>You may receive marketing emails delivered through The Rocket Science Group LLC d/b/a Mailchimp. You can unsubscribe from these emails at any time by clicking the link in any email.</p>
            </div>
        );
    }
}