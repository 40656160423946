export const DISCORD_INVITE_LINK = "https://discord.com/invite/WhetCTtMxS";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyAbW-MzEGL_NuVl9aujNYUClWKCPZiYCgk",
    authDomain: "hide-game.firebaseapp.com",
    databaseURL: "https://hide-game.firebaseio.com",
    projectId: "hide-game",
    storageBucket: "hide-game.appspot.com",
    messagingSenderId: "843879140769",
    appId: "1:843879140769:web:c07ce694d72c87d5c80fe0",
    measurementId: "G-FE125LTKVC"
};

export let FIREBASE_FUNCTIONS_BASE_URL = "https://us-central1-hide-game.cloudfunctions.net/";
if(process.env.REACT_APP_FIREBASE_EMULATORS) FIREBASE_FUNCTIONS_BASE_URL = "http://localhost:5001/hide-game/us-central1/";

export const DISCORD_CLIENT_ID = "791184306869108747";


export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const USERNAME_VALIDATION_REGEX = /^[a-zA-Z0-9_]+$/;

export const FLAIR_COLORS = {
    supporter: "#f0a400",
    dev: "#FF0000"
};

/**
 * Gets the hex color of a flair by ID.
 * @param {string} flair The ID of the flair.
 * @returns {string|null} The hex code of the flair color, starting with a `#`
 */
export function getFlairColor(flair){
    if(flair == "") return null;
    if(!(flair in FLAIR_COLORS)){
        console.error("Invalid flair color " + flair);
        return null;
    }
    return FLAIR_COLORS[flair];
}

export function uuidv4() { // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * Gets the Discord avatar of a user, or if they do not have one, their default avatar.
 * @param {string|null} avatarHash The avatar hash of the user.
 * @param {string} discriminator The 4-digit discriminator tag of the user.
 * @returns {string|null} A src URL for the avatar, or null if there is an error.
 */
export function getDiscordAvatarUrl(avatarHash, userId, discriminator){
    if(avatarHash == null){
        const discriminatorInt = parseInt(discriminator);
        if(isNaN(discriminatorInt)) return null;
        return `https://cdn.discordapp.com/embed/avatars/${discriminatorInt % 5}.png`;
    } else {
        return `https://cdn.discordapp.com/avatars/${userId}/${avatarHash}.png`;
    }
}

export const APPLE_DOWNLOAD_URL = "https://apps.apple.com/us/app/hide-and-seek-scramble/id1597702119";
export const MICROSOFT_DOWNLOAD_URL = "https://www.microsoft.com/en-us/p/hide-and-seek-scramble/9nh0bzvhpb0q";
export const STEAM_DOWNLOAD_URL = "https://store.steampowered.com/app/2011330/Hide_And_Seek_Scramble/";