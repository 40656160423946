import React from "react";
import "./styles/GameReviewPage.scss";
import firebase from "firebase/app";
import moment from "moment";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import Title from "../components/Title";
import { faCheck, faPlug, faServer, faTheaterMasks, faTrophy, faWrench } from "@fortawesome/free-solid-svg-icons";
import BunnyIcon from "../resources/bunny.png";
import RobotIcon from "../resources/robot.png";
import VacuumIcon from "../resources/vacuum.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../components/Button";
import { Helmet } from "react-helmet";

export default class GameReviewPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: null,
            gameCode: null,
            error: null
        };
        this.db = firebase.firestore();
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({loading: true, data: null, gameCode: null});
        let docs;
        try {
            docs = await this.db.collection("games").where("flagged", "==", true).where("state", "==", "closed").limit(1).get();
        } catch(e) {
            console.error(e);
            if(e.code === "permission-denied"){
                this.setState({error: "moderation/permission-denied", loading: false});
                return;
            }
            this.setState({error: "moderation/firebase-error", loading: false});
            return;
        }
        if(docs.size === 0) {
            this.setState({error: "moderation/review-queue-empty", loading: false});
            return;
        }
        const doc = docs.docs[0];
        this.setState({
            loading: false,
            data: doc.data(),
            gameCode: doc.id
        });
    }

    resolve = async () => {
        if(this.state.gameCode == null) return;
        this.setState({loading: true});
        try {
            await this.db.collection("games").doc(this.state.gameCode).update({
                flagged: false
            });
        } catch(e){
            console.error(e);
            this.setState({error: "moderation/firebase-error", loading: false});
            return;
        }
        this.fetchData();
    }
    
    render(){
        const helmet = (
            <Helmet>
                <title>Moderation - Hide And Seek Scramble!</title>
            </Helmet>
        );
        if(this.state.error != null){
            return (
                <div className="purple stripesbg main-content">
                    {helmet}
                    <ErrorMessage code={this.state.error} />
                </div>
            );
        }
        if(this.state.loading || this.state.data == null){
            return (
                <div className="purple stripesbg">
                    {helmet}
                    <Loader />
                </div>
            )
        }
        const {data} = this.state;
        const renderTeamInfo = (team) => {
            const uid = data.teams[team];
            let icon;
            if(team === "bunny") icon = BunnyIcon;
            else if(team === "robot") icon = RobotIcon;
            else if(team === "vacuum") icon = VacuumIcon;

            const isWinner = team === "vacuum" ?
                (data.winner === "vacuum") :
                (data.winner === "robot");

            return (
                <li className="team-info">
                    <img className="team-icon" src={icon} alt={team} />
                    <a className="unstyled" href={`/moderation/users/${uid}`} target="_blank" rel="noreferrer">
                        <code>{uid}</code>
                    </a>
                    { isWinner && <FontAwesomeIcon icon={faTrophy} color="#FCBA03" /> }
                    { data.masterClient === uid && <FontAwesomeIcon icon={faServer} color="#51A3FF" /> }
                    { (data.endCausedByDisconnect && data.disconnectedPlayer === uid) && <FontAwesomeIcon icon={faPlug} color="#FF0000" /> }
                </li>
            );
        }

        const getTeamIcon = (uid) => {
            const team = Object.keys(data.teams).find(i => data.teams[i] === uid);
            let icon;
            if(team === "bunny") icon = BunnyIcon;
            else if(team === "robot") icon = RobotIcon;
            else if(team === "vacuum") icon = VacuumIcon;
            return (
                <a className="unstyled" href={`/moderation/users/${uid}`} target="_blank" rel="noreferrer">
                    <img className="team-icon" src={icon} alt={team} />
                </a>
            );
        }

        return (
            <div className="purple stripesbg" id="game-review-page-main">
                {helmet}
                <div style={{textAlign: "center"}}>
                    <Title id="main-title" content="Game Review Queue" icon={faWrench} /><br />
                    <Button content="Resolve" icon={faCheck} onClick={this.resolve} />
                    <div id="split-pane">
                        <div id="game-info">
                            <h2>Game Information</h2>
                            <p><b>Game code</b> <code>{this.state.gameCode}</code></p>
                            <p><b>Game type:</b> {data.private ? "private" : "public"}</p>
                            <p><b>Reserved on</b> {data.reservedAt == null ? "N/A" : moment(data.reservedAt.toDate()).format("MMM Do, YYYY [at] h:mm a")}</p>
                            <p><b>Started on</b> {data.startedAt == null ? "N/A" : moment(data.startedAt.toDate()).format("MMM Do, YYYY [at] h:mm a")}</p>
                            <p><b>Finished on</b> {data.finishedAt == null ? "N/A" : moment(data.closedAt.toDate()).format("MMM Do, YYYY [at] h:mm a")}</p>
                            <p><b>Closed on</b> {data.closedAt == null ? "N/A" : moment(data.closedAt.toDate()).format("MMM Do, YYYY [at] h:mm a")}</p>
                            {
                                data.endCausedByDisconnect === true && (
                                    <p className="negative">Game ended due to player disconnect</p>
                                )
                            }
                            <h2>Players</h2>
                            <ul id="player-list">
                                {renderTeamInfo("bunny")}
                                {renderTeamInfo("robot")}
                                {renderTeamInfo("vacuum")}
                            </ul>
                            <h2>Objectives</h2>
                            <ul id="objective-list">
                                {
                                    data.objectives.map(i => (
                                        <li key={i}>{i}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div id="flags">
                            <h2>Flags</h2>
                            {
                                data.flags.map((flag, idx) => {
                                    return (
                                        <div className="flag-entry" key={idx}>
                                            <p>
                                                Against:
                                                {getTeamIcon(flag.against)}
                                            </p>
                                            <p>
                                                Reporter: 
                                                {getTeamIcon(flag.reporter)}
                                            </p>
                                            <p>Reason: {flag.reason}</p>
                                            <p>Description:<br />{flag.description}</p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div id="chat">
                            <h2>Chat</h2>
                            {
                                data.chat.map((msg, idx) => {
                                    const team = Object.keys(data.teams).find(i => data.teams[i] === msg.sender);
                                    let icon;
                                    if(team === "bunny") icon = BunnyIcon;
                                    else if(team === "robot") icon = RobotIcon;
                                    else if(team === "vacuum") icon = VacuumIcon;
                                    return (
                                        <p className="chat-entry" key={idx}>
                                            {getTeamIcon(msg.sender)}
                                            {msg.isAllChat && (
                                                <span className="negative">[ALL] </span>
                                            )}
                                            {msg.message}
                                        </p>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
