const ERRORS = {
    "firebase-id-token-error": {
        title: "Could not change username",
        message: "Try signing out and signing back in again. If this keeps occurring, contact us."
    },
    "fetch-user/unknown": {
        title: "Error retrieving data",
        message: "Something went wrong. Please try again."
    },
    "reset-password/unknown": {
        title: "Something went wrong",
        message: "We could not reset your password. Please contact us for support."
    },
    "change-username/unknown": {
        title: "Could not change username",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "change-username/invalid-body": {
        title: "Could not change username",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "change-username/missing-fields": {
        title: "Could not change username",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "change-username/invalid-length": {
        title: "Username too short or too long!",
        message: "Your username must be at least 3 characters long, and at most 16 characters long."
    },
    "change-username/illegal-characters": {
        title: "Invalid characters in username",
        message: "You may only use letters a-z and A-Z, digits 0-9, and underscores in your username."
    },
    "change-username/profanity-filter": {
        title: "Username not available",
        message: "Please do not include profanity in your username. Trying to circumvent the profanity filter may result in a ban."
    },
    "change-username/unauthorized": {
        title: "Could not change username",
        message: "Try signing out and signing back in again. If this keeps occurring, contact us."
    },
    "change-username/internal/firebase-op-error-get": {
        title: "Could not change username",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "change-username/internal/firebase-op-error-update": {
        title: "Could not change username",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "change-username/user-doc-nonexistent": {
        title: "Could not change username",
        message: "An unexpected error occured. Please try again in a few seconds, or contact us for support."
    },
    "change-username/corrupted-data": {
        title: "Could not change username",
        message: "An unexpected error occured. Please contact us for support."
    },
    "change-username/username-locked": {
        title: "Username locked",
        message: "We have locked your account's username. Please contact us for more information."
    },
    "change-username/too-soon": {
        title: "Username changed too quickly",
        message: "You can change your username once every 7 days."
    },
    "change-username/null-content": {
        title: "Could not change username",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "change-username/invalid-content": {
        title: "Could not change username",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "set-flair/unauthorized": {
        title: "Could not complete operation",
        message: "An unexpected error occured. Try signing out and signing back in."
    },
    "set-flair/missing-fields": {
        title: "Could not complete operation",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "set-flair/internal/firebase-op-error-get": {
        title: "Could not complete operation",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "set-flair/user-doc-nonexistent": {
        title: "Could not complete operation",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "set-flair/corrupted-data": {
        title: "Could not complete operation",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "set-flair/flair-not-unlocked": {
        title: "Could not complete operation",
        message: "You haven't unlocked that color yet!"
    },
    "set-flair/internal/firebase-op-error-update": {
        title: "Could not complete operation",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "oauth2/unknown-service": {
        title: "Invalid URL",
        message: "Nothing to see here..."
    },
    "oauth2/invalid-state": {
        title: "Could not link account",
        message: "Something went wrong! Please try again."
    },
    "oauth2/missing-auth-state": {
        title: "Could not link account",
        message: "Something went wrong! Please try again."
    },
    "link-discord/backend/unauthorized": {
        title: "Could not link account",
        message: "Try signing out and signing back in again."
    },
    "link-discord/backend/missing-fields": {
        title: "Could not link account",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "link-discord/backend/auth-code-flow-error-response": {
        title: "Could not link account",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "link-discord/backend/auth-code-flow-error": {
        title: "Could not link account",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "link-discord/backend/get-user-error-response": {
        title: "Could not link account",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "link-discord/backend/get-user-error": {
        title: "Could not link account",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "link-discord/backend/user-doc-nonexistent": {
        title: "Could not link account",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "link-discord/backend/firebase-op-error-update": {
        title: "Could not link account",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "link-discord/backend/firebase-op-error-get": {
        title: "Could not link account",
        message: "An unexpected error occured. Please try again or contact us for support."
    },
    "link-discord/backend/account-already-linked": {
        title: "Account already linked",
        message: "This Discord profile has already been linked to a different account. If you do not believe this was you, contact us for support."
    },
    "discord-refresh-request/cloud-functions-unknown": {
        title: "Unknown error",
        message: "An internal error has occured. Please try again or contact us for support."
    },
    "discord-refresh-request/server-unknown": {
        title: "Unknown error",
        message: "An internal error has occured. Please try again or contact us for support."
    },
    "discord-refresh-request/invalid-body": {
        title: "Unknown error",
        message: "An internal error has occured. Please try again or contact us for support."
    },
    "discord-refresh-request/unauthorized": {
        title: "Unknown error",
        message: "An internal error has occured. Please try again or contact us for support."
    },
    "discord-refresh-request/forbidden": {
        title: "Unknown error",
        message: "An internal error has occured. Please try again or contact us for support."
    },
    "moderation/review-queue-empty": {
        title: "Nothing left to review!",
        message: "Awesome, everything has been resolved."
    },
    "moderation/user-nonexistent": {
        title: "Player does not exist",
        message: "We couldn't find a player with that ID. Please check the URL."
    },
    "moderation/permission-denied": {
        title: "Permission denied",
        message: "This page is only accessible by moderators. If you are a moderator, make sure you are signed in to the right account."
    },
    "moderation/firebase-error": {
        title: "Unknown error",
        message: "Please let us know if this keeps happening."
    },
    "delete-user/unauthorized": {
        title: "Could not delete account",
        message: "Try signing out and signing back in again. If this keeps occurring, contact us."
    },
    "delete-user/firebase-error": {
        title: "Could not delete account",
        message: "Something went wrong. If this keeps occurring, contact us."
    },
    "delete-user/user-doc-nonexistent": {
        title: "Could not delete account",
        message: "Something went wrong. If this keeps occurring, contact us."
    },
    "delete-user/unknown": {
        title: "Could not delete account",
        message: "Something went wrong. If this keeps occurring, contact us."
    },
    "delete-user/null-content": {
        title: "Could not delete account",
        message: "Something went wrong. If this keeps occurring, contact us."
    },
    "delete-user/invalid-content": {
        title: "Could not delete account",
        message: "Something went wrong. If this keeps occurring, contact us."
    },
};

export default ERRORS;