import "./styles/DiscordPage.scss";
import React from "react";
import Title from "../components/Title";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import Button from "../components/Button";
import { DISCORD_INVITE_LINK} from "../shared";
import { faCog, faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class DiscordPage extends React.Component {

    render(){
        return (
            <div className="purple stripesbg main-content" id="discord-page-main">
                <Helmet>
					<title>Discord - Hide And Seek Scramble!</title>
				</Helmet>
                <div style={{textAlign: "center"}}>
                    <Title content="Discord Server" icon={faDiscord} />
                    <h2>1. Join the server!</h2>
                    <a href={DISCORD_INVITE_LINK} target="_blank" rel="noopener noreferrer">
                        <Button size="jumbo" content="Get invite link" icon={faLink} />
                    </a>
                    <h2>2. Link your account</h2>
                    <Link to="/settings#integrations">
                        <Button size="jumbo" content="Go to settings" icon={faCog} />
                    </Link>
                </div>
            </div>
        );
    }
}