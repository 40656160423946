import React from "react";
import "./styles/LandingPage.scss";
import {Link} from "react-router-dom";
import Button from "../components/Button";
import Logo from "../resources/logo-full.png";
import {faDiscord} from "@fortawesome/free-brands-svg-icons";
import RobotImg from "../resources/robot-shadow.png";
import BunnyImg from "../resources/bunny-shadow.png";
import VacuumImg from "../resources/vacuum-shadow.png";
import LandscapeImg from "../resources/landscape.png";
import DownloadButtons from "../components/DownloadButtons";
import { APPLE_DOWNLOAD_URL, MICROSOFT_DOWNLOAD_URL, STEAM_DOWNLOAD_URL } from "../shared";
import { Helmet } from "react-helmet";

class LandingPage extends React.Component {
    render(){
        const seoStructuredData = (
            <script type="application/ld+json">
                {
                    `
                        {
                            "@context": "https://schema.org",
                            "@type": "SoftwareApplication",
                            "name": "Hide And Seek Scramble!",
                            "operatingSystem": ["OSX", "Windows"],
                            "applicationCategory": "GameApplication",
                            "installUrl": ["${APPLE_DOWNLOAD_URL}", "${MICROSOFT_DOWNLOAD_URL}", "${STEAM_DOWNLOAD_URL}"],
                            "offers": {
                                "@type": "Offer",
                                "price": "0"
                            }
                        }
                    `
                }
            </script>
        );

        return (
            <div className="purple stripesbg" id="landing-page-main">
                <Helmet>
                    {seoStructuredData}
                </Helmet>
                <div id="landscape" style={{
                    backgroundImage: `url(${LandscapeImg})`
                }}>
                    <div id="logo-container">
                        <img src={Logo} alt="Hide And Seek Scramble!" id="logo" />
                    </div>
                    <DownloadButtons />
                    <h1 id="summary">Three players. Two teams. One kidnapped bunny!</h1>
                    <iframe id="video-embed" src="https://www.youtube.com/embed/QzDs7yPJfiU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
                <h2 id="characters-text">Play as three different characters in this fast-paced, competitive multiplayer game.</h2>
                <div id="characters">
                    <div id="vacuum-info">
                        <img src={VacuumImg} alt="Vacuum" />
                        <h3>Vacuum</h3>
                        <p>As the Vacuum, you've captured the Bunny and are being chased! Explore the city and complete unique tasks before you're caught.</p>
                    </div>
                    <div id="bunny-info">
                        <img src={BunnyImg} alt="Bunny" />
                        <h3>Bunny</h3>
                        <p>As the Bunny, your job is to communicate with the Robot! Give clues to your whereabouts as you see landmarks and other hints around the world.</p>
                    </div>
                    <div id="robot-info">
                        <img src={RobotImg} alt="Robot" />
                        <h3>Robot</h3>
                        <p>As the Robot, use information from the Bunny to track down and stop the evil Vacuum! Ask questions and stay observant to gain the upper hand.</p>
                    </div>
                </div>
                <h1 id="download-text">Download for free!</h1>
                <DownloadButtons />
                <h2 id="community-links-text">Join the community</h2>
                <Link to="/discord">
                    <Button content="Discord server" icon={faDiscord} />
                </Link>
                <div id="footer">
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <p><a className="unstyled" href="https://yoonicode.com?utm_source=hss&utm_medium=web&utm_campaign=hss-footer" target="_blank">© 2022 Yoonicode</a></p>
                </div>
            </div>
        );
    }
}

export default LandingPage;