import React from "react";
import PropTypes from "prop-types";
import Message from "./Message";
import ERRORS from "../errors";

class ErrorMessage extends React.Component {
    render(){
        return (
            <Message size="small" type="negative" content={(
                <>
                    <b>{ERRORS[this.props.code].title}</b> <br />
                    <span>{ERRORS[this.props.code].message}</span> <br />
                    <span style={{
                        fontSize: "11px",
                        opacity: "80%"
                    }}>
                        {this.props.code}
                    </span>
                </>
            )} style={this.props.style} />
        )
    }
}

ErrorMessage.propTypes = {
    code: PropTypes.string,
    style: PropTypes.object
};

ErrorMessage.defaultProps = {
    style: null
};

export default ErrorMessage;