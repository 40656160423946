import React from "react";
import PropTypes from "prop-types";

class Message extends React.Component {
    render(){
        return (
            <div className={`message ${this.props.type} ${this.props.size}`} style={this.props.style}>
                <p>{this.props.content}</p>
            </div>
        );
    }
}

Message.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    type: PropTypes.oneOf(["info", "negative", "positive"]),
    size: PropTypes.oneOf(["regular", "small", "jumbo"])
};

Message.defaultProps = {
    type: "info",
    size: "regular"
};

export default Message;