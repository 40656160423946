import React from "react";
import firebase from "firebase/app";
import moment from "moment";
import "./styles/SettingsPage.scss";
import { faCog, faKey, faHeart, faTrash, faTimes } from "@fortawesome/free-solid-svg-icons";
import ERRORS from "../errors";
import {Link} from "react-router-dom";
import Title from "../components/Title";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Button from "../components/Button";
import Input from "../components/Input";
import { FIREBASE_FUNCTIONS_BASE_URL, USERNAME_VALIDATION_REGEX, getFlairColor } from "../shared";
import ErrorMessage from "../components/ErrorMessage";
import LinkDiscordButton from "../components/LinkDiscordButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../components/Modal";
import { Helmet } from "react-helmet";

export default class SettingsPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: null,
            error: null,
            passwordResetSuccess: false,
            deleteModalShown: false,
            newUsernameField: "",
            usernameChangeResult: ""
        };
        this.db = firebase.firestore();
        this.functions = firebase.functions();
        this.auth = firebase.auth();
        this.fetchData();
    }

    fetchData = async () => {
        let data;
        try {
            data = await this.db.collection("users").doc(this.props.firebaseUser.uid).get();
        } catch(e) {
            console.error(e);
            this.setState({error: "fetch-user/unknown", loading: false});
        }
        this.setState({data: data.data(), loading: false});
    }

    getFirebaseIdToken = async () => {
        try {
            return await this.props.firebaseUser.getIdToken();
        } catch(e) {
            console.error(e);
            this.setState({loading: false, error: "firebase-id-token-error"});
            return;
        }
    }
    
    sendPasswordResetEmail = () => {
        if(this.state.passwordResetSuccess) return;
        this.setState({loading: true});
        firebase.auth().sendPasswordResetEmail(this.props.firebaseUser.email).then(() => {
            this.setState({passwordResetSuccess: true, loading: false});
        }, (e) => {
            console.error(e);
            this.setState({error: "reset-password/unknown", loading: false});
        });
    }

    changeUsername = async () => {
        const username = this.state.newUsernameField;
        if(username.length == 0) return;
        if(username.length < 3 || username.length > 16){
            this.setState({usernameChangeResult: "change-username/invalid-length"});
            return;
        }
        if(!USERNAME_VALIDATION_REGEX.test(username)){
            this.setState({usernameChangeResult: "change-username/illegal-characters"});
            return;
        }
        this.setState({loading: true});
        const idToken = await this.getFirebaseIdToken();
        let res;
        try {
            res = await fetch(FIREBASE_FUNCTIONS_BASE_URL + "changeUsername", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + idToken
                },
                body: JSON.stringify({
                    username: username
                })
            });
        } catch(e) {
            console.error(e);
            this.setState({loading: false, usernameChangeResult: "change-username/unknown"});
            return;
        }
        let body;
        try {
            body = await res.json();
        } catch(e) {
            console.error(e);
            this.setState({loading: false, usernameChangeResult: "change-username/null-content"});
            return;
        }
        if(body.success == null){
            this.setState({loading: false, usernameChangeResult: "change-username/invalid-content"});
            return;
        }
        if(body.success === true){
            this.setState({loading: false, usernameChangeResult: "success", newUsernameField: ""});
        } else {
            let ecode;
            if(body.error == null || !(body.error in ERRORS)){ ecode = "change-username/unknown"; }
            else { ecode = body.error; }
            this.setState({loading: false, usernameChangeResult: ecode});
        }
    }

    setFlair = async (flair) => {
        this.setState({loading: true});
        const fn = this.functions.httpsCallable("setFlair");
        try {
            await fn({
                flair: flair
            });
        } catch(e) {
            console.log(e.code + ": " + e.message);
            this.setState({loading: false, error: e.message});
            return;
        }
        await this.fetchData();
    }

    deleteAccount = async () => {
        this.setState({loading: true});
        const idToken = await this.getFirebaseIdToken();
        let res;
        try {
            res = await fetch(FIREBASE_FUNCTIONS_BASE_URL + "deleteUser", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + idToken
                }
            });
        } catch(e) {
            console.error(e);
            this.setState({loading: false, error: "delete-user/unknown"});
            return;
        }
        let body;
        try {
            body = await res.json();
        } catch(e) {
            console.error(e);
            this.setState({loading: false, error: "delete-user/null-content"});
            return;
        }
        if(body.success == null){
            this.setState({loading: false, error: "delete-user/invalid-content"});
            return;
        }
        if(body.success === true){
            await this.auth.signOut();
            return;
        } else {
            let ecode;
            if(body.error == null || !(body.error in ERRORS)){ ecode = "delete-user/unknown"; }
            else { ecode = body.error; }
            this.setState({loading: false, error: ecode});
        }
    }
    
    render(){
        const helmet = (
            <Helmet>
                <title>Settings - Hide And Seek Scramble!</title>
            </Helmet>
        );
        if(this.state.error != null){
            return (
                <div className="purple stripesbg main-content">
                    {helmet}
                    <ErrorMessage code={this.state.error} style={{marginTop: "10px"}} />
                </div>
            );
        }
        if(this.state.loading || this.state.data == null){
            return (
                <div className="purple stripesbg">
                    {helmet}
                    <Loader />
                </div>
            )
        }
        const {data} = this.state;
        return (
            <div className="purple stripesbg main-content" id="settings-page-main">
                {helmet}
                <Modal shown={this.state.deleteModalShown} content={
                    <>
                        <h1>Delete account?</h1>
                        <p>All data will be lost, along with any unlockables you have purchased.</p>
                    </>
                } footer={
                    <>
                        <Button content="Cancel" icon={faTimes} onClick={() => {
                            this.setState({deleteModalShown: false});
                        }} />
                        <Button content="Delete" icon={faTrash} onClick={this.deleteAccount} />
                    </>
                } />
                <div style={{textAlign: "center"}}>
                    <Title content="Settings" icon={faCog} />
                </div>
                <h2 id="security">Login and Security</h2>
                <Button size="small" content="Reset password" icon={faKey} onClick={this.sendPasswordResetEmail} disabled={this.state.passwordResetSuccess || this.state.loading} />
                {
                    this.state.passwordResetSuccess ? (
                        <Message size="small" type="positive" content="A password reset link has been sent to your email address." style={{marginTop: "10px"}} /> 
                    ) : null
                }
                <h2 id="profile">Profile</h2>
                <h3>Change username</h3>
                {
                    data.usernameLocked ? (
                        <Message size="small" type="info" content="We have locked your account's username. Please contact us for more information." />
                    ) : (
                        <>
                            <Input size="small" placeholder="New username" value={this.state.newUsernameField} onChange={(e) => {
                                this.setState({newUsernameField: e.target.value});
                            }} style={{marginRight: "10px"}} />
                            <Button size="small" content="Confirm" disabled={this.state.newUsernameField.length < 3} onClick={this.changeUsername} />
                        </>
                    )
                }
                {
                    this.state.usernameChangeResult == "" ? null : (
                        this.state.usernameChangeResult == "success" ? (
                            <Message size="small" type="positive" content="Username changed successfully!" style={{marginTop: "10px"}} /> 
                        ) : (
                            <ErrorMessage code={this.state.usernameChangeResult} style={{marginTop: "10px"}} />
                        )
                    )
                }
                <ul>
                    <li>Usernames must be 3-16 characters long.</li>
                    <li>Characters allowed: A-Z, a-z, 0-9, and underscores (_)</li>
                    <li>Usernames may not contain profanity or objectionable content. Please keep the game fun for everyone!</li>
                    <li>You can change your username once every 7 days.</li>
                </ul>
                <h2 id="appearance">Appearance</h2>
                <h3>Nametag color</h3>
                {
                    data.unlockables.unlockedFlairs.length == 0 ? (
                        <>
                            <p>
                                Unlock nametag colors, gain access to an exclusive Discord hangout, and more by donating to help cover development and server costs.
                            </p>
                            <p>Click the Customize button in-game to learn more.</p>
                        </>
                    ) : (
                        <div>
                            <div className={`flair-selection ${data.unlockables.flairChoice == "" ? "selected" : ""}`} style={{
                                backgroundColor: "#FFFFFF"
                            }} onClick={() => {
                                this.setFlair("");
                            }} />
                            {
                                data.unlockables.unlockedFlairs.map((flair) => {
                                    return (
                                        <div className={`flair-selection ${data.unlockables.flairChoice == flair ? "selected" : ""}`} style={{
                                            backgroundColor: getFlairColor(flair)
                                        }} onClick={() => {
                                            this.setFlair(flair);
                                        }} />
                                    );
                                })
                            }
                        </div>
                    )
                }
                <h2 id="integrations">Integrations</h2>
                <LinkDiscordButton size="small" data={this.state.data.integrations.discord} />
                {/* <h2>Subscriptions</h2> */}
                <h2>Account</h2>
                <Button size="small" content="Delete account" icon={faTrash} onClick={() => {
                    this.setState({deleteModalShown: true});
                }} />
            </div>
        );
    }
}