import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Title extends React.Component {
    render(){
        const hasIcon = this.props.icon != null;
        return (
            <div className={`title ${hasIcon ? "with-icon" : ""}`} {...this.props}>
                {
                    hasIcon ? (
                        <>
                            <FontAwesomeIcon icon={this.props.icon} /><br />
                            <h1>{this.props.content}</h1>
                        </>
                    ) : (
                        <h1>{this.props.content}</h1>
                    )
                }
            </div>
        );
    }
}

Title.propTypes = {
    icon: PropTypes.object,
    content: PropTypes.string.isRequired
};

export default Title;