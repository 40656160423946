import React from "react";
import "./styles/AuthPage.scss";
import firebase from "firebase/app";
import Button from "../components/Button";
import Input from "../components/Input";
import Loader from "../components/Loader";
import {validateEmail} from "../shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import Message from "../components/Message";
import { Helmet } from "react-helmet";

export default class AuthPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false,
            error: null
        }
        this.auth = firebase.auth();
        let params = new URLSearchParams(window.location.search);
        this.redirectUrl = params.get("dest");
        if(this.redirectUrl == "" || this.redirectUrl == undefined){
            this.redirectUrl = "/";
            return;
        }else{
            this.redirectUrl = decodeURIComponent(this.redirectUrl);
        }
    }
    
    signIn = () => {
        this.setState({loading: true});
        if(!this.checkValidity()){
            this.setState({loading: false});
            return;
        }
        this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
            this.auth.signInWithEmailAndPassword(this.state.email, this.state.password).then((user) => {
                window.location.replace(this.redirectUrl);
            }, (e) => {
                console.error(e);
                switch(e.code){
                    case "auth/invalid-email":
                        this.setState({error: "The email you entered is invalid. Check for mistakes and try again.", loading: false});
                        break;
                    case "auth/user-disabled":
                        this.setState({error: "Your account has been disabled. Please contact us for assistance.", loading: false});
                        break;
                    case "auth/user-not-found":
                        this.setState({error: "There is no account associated with that email. You can sign up in-game.", loading: false});
                        break;
                    case "auth/wrong-password":
                        this.setState({error: "The password you entered is incorrect. Check for mistakes and try again.", loading: false});
                        break;
                    default:
                        this.setState({error: "An unknown error occured. Please try again later.", loading: false});
                        break;
                }
            });
        }, (e) => {
            console.error(e);
            this.setState({error: "An unknown error occured. Please try again later.", loading: false});
        });
    }

    checkValidity = () => validateEmail(this.state.email) && this.state.password.length > 0;

    render(){
        return (
            <div className="purple stripesbg main-content" id="auth-page-main">
                <Helmet>
					<title>Log in - Hide And Seek Scramble!</title>
				</Helmet>
                <div id="parent">
                    <div id="login-box">
                        {
                            this.state.loading ? <Loader /> : null
                        }
                        <h1>Welcome!</h1>
                        <Input size="jumbo" placeholder="Email address" style={{marginBottom: "10px", marginTop: "20px", width: "100%"}} value={this.state.email} onChange={(e) => {
                            this.setState({email: e.target.value});
                        }} />
                        <Input size="jumbo" placeholder="Password" type="password" style={{width: "100%"}} value={this.state.password} onChange={(e) => {
                            this.setState({password: e.target.value});
                        }} />
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "10px"
                        }}>
                            <a className="unstyled" href="/forgot-password" style={{opacity: "75%"}}>
                                Forgot password?
                            </a>
                            <Button iconPosition="right" icon={faArrowRight} content="Sign in" disabled={!this.checkValidity()} onClick={this.signIn} />
                        </div>
                        {this.state.error == null ? null : (
                            <Message type="negative" content={this.state.error} style={{
                                marginTop: "20px",
                                maxWidth: "300px"
                            }} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}