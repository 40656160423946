import React from "react";
import {Route} from "react-router-dom";
import firebase from "firebase/app";
import PropTypes from "prop-types";
import Loader from "./Loader";

class PrivateRoute extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: null
        };

        firebase.auth().onAuthStateChanged((user) => {
            if(user){
                this.setState({user: user});
            } else {
                window.location.replace("/auth?dest=" + encodeURIComponent(window.location.pathname + window.location.search));
            }
        });
    }
    
    render(){
        if(this.state.user == null){
            return (
                <Loader />
            );
        }
        const Elem = this.props.component;
        return (
            <Route path={this.props.path} render={props => (
                <Elem firebaseUser={this.state.user} {...props} />
            )} />
        );
    }
}

PrivateRoute.propTypes = {
    component: PropTypes.element.isRequired
};

export default PrivateRoute;