import React from "react";
import "./styles/ForgotPasswordPage.scss";
import { validateEmail } from "../shared";
import firebase from "firebase/app";
import "firebase/auth";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import Title from "../components/Title";
import Button from "../components/Button";
import Input from "../components/Input";
import { Helmet } from "react-helmet";

export default class ForgotPasswordPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email: "",
            loading: false,
            error: null,
            success: false
        };
    }

    sendPasswordResetEmail = () => {
        if(!validateEmail(this.state.email)) return;
        this.setState({loading: true});
        firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
            this.setState({success: true, loading: false});
        }, (e) => {
            console.error(e);
            this.setState({error: "reset-password/unknown", loading: false});
        });
    }
    
    render(){
        const helmet = (
            <Helmet>
                <title>Forgot password - Hide And Seek Scramble!</title>
            </Helmet>
        );
        if(this.state.error != null){
            return (
                <div className="purple stripesbg main-content">
                    {helmet}
                    <ErrorMessage code={this.state.error} style={{marginTop: "10px"}} />
                </div>
            );
        }
        if(this.state.loading){
            return (
                <div className="purple stripesbg">
                    {helmet}
                    <Loader />
                </div>
            )
        }
        return (
            <div className="purple stripesbg main-content" id="forgot-password-main">
                {helmet}
                <div style={{textAlign: "center"}}>
                    <Title content="Reset Password" icon={faKey} />
                    <div className="divider" />
                    <p>Forgot your password? We can send you a password reset email.</p>
                    <div className="divider" />
                    {
                        this.state.success ? (
                            <p id="success-message">
                                Alright! We've sent an email with a password reset link.
                            </p>
                        ) : (
                            <>
                                <Input placeholder="Email address" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
                                <Button id="btn" icon={faKey} content="Reset password" onClick={this.sendPasswordResetEmail} disabled={!validateEmail(this.state.email)} />
                            </>
                        )
                    }
                </div>
            </div>
        );
    }
}