import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import Button from "./Button";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Wordmark from "../resources/wordmark.png";

class Navbar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: null
        };
        this.auth = firebase.auth();
        this.auth.onAuthStateChanged((user) => {
            if(user){
                this.setState({user: user});
            }
        });
    }

    signOut = async () => {
        try {
            await this.auth.signOut();
        } catch(e) {
            console.error(e);
        }
    }
    
    render(){
        const {user} = this.state;
        const isSignedIn = user !== null;
        return (
            <div id="navbar">
                <div id="left-content">
                    <NavbarButton to="/" content={(
                        <img src={Wordmark} alt="Hide And Seek Scramble!" />
                    )} />
                </div>
                <div id="right-content">
                    {
                        isSignedIn ? (
                            <>
                                <NavbarButton to="/profile" content="Profile" />
                                <NavbarButton to="/settings" content="Settings" />
                                <NavbarButton content="Sign out" onClick={this.signOut} />
                            </>
                        ) : (
                            <>
                                <NavbarButton to={"/auth?dest=" + encodeURIComponent("/profile")} content="Sign in" />
                            </>
                        )
                    }
                </div>
            </div>
        );
    }
}

Navbar.propTypes = {
    
};

export default Navbar;

class NavbarButton extends React.Component {
    render(){
        const content = ((typeof this.props.content) === "string") ? (
            <div><p>{this.props.content}</p></div>
        ) : (
            <div>{this.props.content}</div>
        );
        if(this.props.to != null){
            return (
                <Link to={this.props.to} className="navbar-button">{content}</Link>
            )
        }
        return (
            <div className="navbar-button" onClick={this.props.onClick}>{content}</div>
        )
    }
}

NavbarButton.propTypes = {
    to: PropTypes.string,
    onClick: PropTypes.func,
    content: PropTypes.string.isRequired
};