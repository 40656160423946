import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class Button extends React.Component {
    render() {
        const iconOnly = this.props.content.length == 0;
        const icon = this.props.icon == null ? null : (
            <FontAwesomeIcon icon={this.props.icon} style={{
                marginLeft: (this.props.iconPosition == "right" && !iconOnly) ? "10px" : 0,
                marginRight: (this.props.iconPosition == "left" && !iconOnly) ? "10px" : 0
            }} />
        );
        return (
            <button id={this.props.id} className={`custom ${this.props.size} ${this.props.disabled ? "disabled" : ""} ${iconOnly ? "icon-only" : ""}`} style={this.props.style} onClick={this.props.onClick}>
                {this.props.iconPosition == "left" ? icon : null}
                {this.props.content}
                {this.props.iconPosition == "right" ? icon : null}
            </button>
        );
    }
}

Button.propTypes = {
    content: PropTypes.string,
    size: PropTypes.oneOf(["regular", "small", "jumbo"]),
    disabled: PropTypes.bool,
    icon: PropTypes.object,
    iconPosition: PropTypes.oneOf(["left", "right"]),
    onClick: PropTypes.func,
    id: PropTypes.string
};

Button.defaultProps = {
    size: "regular",
    iconPosition: "left",
    content: ""
}

export default Button;