import "./customstyles.scss";
import React from "react";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import {FIREBASE_CONFIG} from "./shared";
import { Helmet } from "react-helmet";

import LandingPage from "./pages/LandingPage";
import AuthPage from "./pages/AuthPage";
import SettingsPage from "./pages/SettingsPage";
import ProfilePage from "./pages/ProfilePage";
import NotFoundPage from "./pages/NotFoundPage";
import OAuthCallbackPage from "./pages/OAuthCallbackPage";
import PrivateRoute from "./components/PrivateRoute";
import Navbar from "./components/Navbar";
import UserReviewPage from "./pages/UserReviewPage";
import GameReviewPage from "./pages/GameReviewPage";
import DiscordPage from "./pages/DiscordPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import LegalPage from "./pages/LegalPage";

export default class App extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
		if(firebase.apps.length == 0){
			firebase.initializeApp(FIREBASE_CONFIG);
			this.analytics = firebase.analytics();
			if(process.env.REACT_APP_FIREBASE_EMULATORS == 'true'){
				console.log("Running firebase functions emulators!");
				firebase.functions().useEmulator("localhost", "5001");
			}
		}
	}
	
	render(){
		return (
			<>
				<Helmet>
					<title>Hide And Seek Scramble!</title>
				</Helmet>
				<Router>
					<Navbar />
					<Switch>
						<Route path="/" exact component={LandingPage} />
						<Route path="/auth" component={AuthPage} />
						<Route path="/forgot-password" component={ForgotPasswordPage} />
						<PrivateRoute path="/settings" component={SettingsPage} />
						<PrivateRoute path="/profile" component={ProfilePage} />
						<Route path="/discord" component={DiscordPage} />
						<Route path="/legal" component={LegalPage} />
						<PrivateRoute path="/oauth2callback/:service" component={OAuthCallbackPage} />
						<PrivateRoute path="/moderation/users/:id?" component={UserReviewPage} />
						<PrivateRoute path="/moderation/games" component={GameReviewPage} />
						<Route path="*" component={NotFoundPage} />
					</Switch>
				</Router>
			</>
		);
	}
}