import "./styles/OAuthCallbackPage.scss";
import React from "react";
import Cookies from "js-cookie";
import firebase from "firebase/app";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import Title from "../components/Title";
import Button from "../components/Button";
import {Link} from "react-router-dom";
import {getDiscordAvatarUrl} from "../shared";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const SERVICES = {
    discord: {
        icon: faDiscord,
        displayName: "Discord"
    }
}

export default class OAuthCallbackPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            success: false,
            error: null,
            successContent: null
        };
        this.functions = firebase.functions();

        this.searchParams = new URLSearchParams(window.location.search);
        this.service = this.props.match.params.service;
        if(this.service === "discord"){
            this.completeDiscordLink();
        }else{
            this.state.error = "oauth2/unknown-service";
        }
    }

    completeDiscordLink = async () => {
        const state = this.searchParams.get("state");
        if(state == "" || state == undefined || Cookies.get("discord_oauth2_state") !== state){
            console.log("Invalid state: " + state);
            this.setState({error: "oauth2/invalid-state"});
            return;
        }
        const code = this.searchParams.get("code");
        if(code == "" || code == undefined){
            this.setState({error: "oauth2/missing-auth-code"});
            return;
        }
        console.log(code);
        const fn = this.functions.httpsCallable("linkDiscordOAuth2");
        let res;
        try {
            res = await fn({
                code: code,
                redirectURI: window.location.origin + window.location.pathname
            });
        } catch(e) {
            console.log(e.code + ": " + e.message);
            this.setState({error: e.message});
            return;
        }
        const avatar = getDiscordAvatarUrl(res.data.avatar, res.data.id, res.data.discriminator);
        this.setState({
            success: true,
            successContent: (
                <>
                    {
                        avatar == null ? null : (
                            <img src={avatar} alt="Discord avatar" id="discord-avatar" />
                        )
                    }
                    <h2>Hi, {res.data.username}#{res.data.discriminator}!</h2>
                </>
            )
        });
    }
    
    render(){
        const helmet = (
            <Helmet>
                <title>Link Account - Hide And Seek Scramble!</title>
            </Helmet>
        );
        if(this.state.error != null){
            return (
                <div className="purple stripesbg main-content" id="oauth-page-main">
                    {helmet}
                    <ErrorMessage code={this.state.error} style={{marginTop: "10px"}} />
                </div>
            )
        }
        const serviceInfo = SERVICES[this.service];
        if(this.state.success === true){
            return (
                <div className="purple stripesbg main-content" id="oauth-page-main">
                    {helmet}
                    <div id="centered-box">
                        <Title content={`${serviceInfo.displayName} linked successfully!`} icon={serviceInfo.icon} />
                        <div id="custom-content">
                            {this.state.successContent}
                        </div>
                        <Link to="/settings">
                            <Button content="Continue" icon={faCheck} size="large" />
                        </Link>
                    </div>
                </div>
            )
        }
        return (
            <div className="purple stripesbg" id="oauth-page-main">
                {helmet}
                <Loader />
            </div>
        )
    }
}